function requireAll(r) { r.keys().forEach(r); }
global.Realtime = require('./js/realtime');
// layout.jade
require('./themes/exploros/scss/theme.scss');
require('./css/all.css');
require('./js/lib/ng-table/bundles/ng-table.css');
require('./js/lib/angular-motion/dist/angular-motion.min.css');
require('./js/lib/bootstrap-additions/dist/bootstrap-additions.min.css');
require('./js/lib/literallyCanvas/literallycanvas.css');
require('./css/xx-http-error-handling.css');
require('./js/lib/bootstrap-tour/css/bootstrap-tour.css');
require('./css/bootstrap-tour-exploros.css');
require('./js/lib/ladda/dist/ladda-themeless.min.css');
require('./js/lib/angular-ui-select/dist/select.css');
require('./js/lib/angular-bootstrap-toggle-switch/style/bootstrap3/angular-toggle-switch-bootstrap-3.css');
requireAll(require.context('../elements/', true, /\.css$/));
require('./js/lib/bootstrap-daterangepicker/daterangepicker.css');
require('./js/lib/angular-multi-select/isteven-multi-select.css');
require('../components/onBoarding/ng-onboarding.css');

global.angular = require('angular');
require('angular-animate');
require('angular-sanitize');
require('angular-cookies');
require('angular-route');
require('angular-resource');

require('./js/lib/angular-strap/dist/angular-strap.js');
require('./js/lib/angular-strap/dist/angular-strap.tpl.js');
require('./js/lib/angular-strap/dist/modules/date-parser.js');
require('./js/lib/angular-ui-bootstrap/ui-bootstrap-tpls-1.2.4.js');
require('./js/lib/bootstrap/bootstrap.js');
require('./js/lib/angular-resize/dist/angular-resize.js');
require('./js/lib/angular-debounce/angular-debounce.js');
global.React = React;
window.LC = require('./js/lib/literallyCanvas/literallycanvas.js');
require('./js/lib/jquery-truncate/jquery.trunk8.js');
require('./js/lib/bootstrap-tour/js/bootstrap-tour-standalone.js');
require('./js/lib/insertionQuery/insQ.js');
require('./js/lib/angular-segment-analytics/segment.min.js');
global.moment = require('moment');
require('./js/lib/bootstrap-daterangepicker/daterangepicker.js');
require('./js/lib/angular-daterangepicker/js/angular-daterangepicker.js');
require('./js/lib/angular-multi-select/isteven-multi-select.js');
require('./js/lib/angular-bootstrap-toggle-switch/angular-toggle-switch.js');
// index.jade

require('./js/utils/JSPolyfill.js');
require('./js/utils/utils.js');

require('./js/xp-widget.js');
require('./js/lib/ng-table/bundles/ng-table.js');
require('./js/lib/angular-uuid-service/uuid-svc.js');
require('./js/lib/angular-inview/angular-inview.js');
require('./js/lib/angular-file-upload/angular-file-upload.js');
require('./js/lib/angular-draganddrop/angular-draganddrop.js');
require('./js/lib/angular-bootstrap-multiselect/dist/angular-bootstrap-multiselect.js');
require('./js/lib/angular-ui-select/dist/select.js');
require('./js/lib/angular-permission/dist/angular-permission.js');
require('./js/lib/angular-permission/dist/angular-permission-ng.js');
require('./js/lib/angular-elastic/elastic.js');
require('./js/lib/ladda/js/spin.js');
require('./js/lib/ladda/js/ladda.js');

require('./js/lib/angular-ladda/dist/angular-ladda.js');
require('./js/lib/angular-d3/angular-d3.js');
require('./js/lib/nw-fileDialog.js');
require('./js/lib/xml2json/xml2json.js');
global.loadImage = require('blueimp-load-image');
require('./js/lib/dotdotdot/jquery.dotdotdot.js');
window.html2pdf = require('./js/lib/html2pdf/html2pdf.bundle.min.js');
require('./js/lib/ngstorage/ngStorage.js');
const classicEditor = require( '@ckeditor/ckeditor5-build-classic' );
window.chart = require('chart.js');
window.OT = require('@opentok/client');
require('opentok-angular');
window.videojs = require('video.js/dist/video.js');
require('!style-loader!css-loader!video.js/dist/video-js.css');

require('./js/lib/angular-google-picker-0.2.2/src/google-picker.js');
require('./js/before_hook.js');
require('./js/xx-http-error-handling.js');
require('./js/services.js');
require('./js/permissions.js');
require('./js/services/loginservice.js');
require('./js/services/alert.js');
require('./js/services/classesGrades.js');
require('./js/services/classesSubjects.js');
require('./js/services/classCodes.js');
require('./js/services/licensePlans.js');
require('./js/services/licenseValidation.js');
require('./js/services/importValidation.js');
require('./js/services/classImportStudents.js');
require('./js/services/importTeachersClassesStudents.js');
require('./js/services/userCourses.js');
require('./js/services/userDefaultInfo.js');
require('./js/services/userManagedSubscriptions.js');
require('./js/services/experienceList.js');
require('./js/services/scoreService.js');
require('./js/services/shuffle.js');
require('./js/services/readingGroupService.js');
require('./js/services/reportingSchoolYearsService.js');
require('./js/services/modalNavigation.js');
require('./js/services/modes/classMode.js');
require('./js/services/modes/curriculumMode.js');
require('./js/services/modes/subscriptionMode.js');
require('./js/services/modes/activeMode.js');
require('./js/services/modes/districtAdminMode.js');
require('./js/services/modes/schoolAdminMode.js');
require('./js/services/reporting/reportNavigation.js');
require('./js/services/reporting/reportLabels.js');
require('./js/services/reporting/reportToCSV.js');
require('./js/services/reporting/reportToPDF.js');
require('./js/services/reporting/noTypeReportsList.js');
require('./js/services/reporting/classReports.js');
require('./js/services/reporting/curriculumReports.js');
require('./js/services/reporting/subscriptionReports.js');
require('./js/services/reporting/savedFilterState.js');
require('./js/services/metrics.js');
require('./js/services/changeStudentPassword.js');
require('./js/services/experience.js');
require('./js/services/classes.js');
require('./js/services/orgCollections.js');
require('./js/services/userTrials.js');
require('./js/services/subscriptionSettings.js');
require('./js/controllers.js');
require('./js/welcome.controller.js');
require('./js/connecttoteacher.form.controller.js');
require('./js/changepassword.form.controller.js');
require('./js/course.settings.controller.js');
require('./js/course.view.controller.js');
require('./js/experience.list.layout.controller.js');
require('./js/experience.list.controller.js');
require('./js/experience.dashboard.students.controller.js');
require('./js/experience.dashboard.responses.controller.js');
require('./js/experience.active.controller.js');
require('./js/experience.pack.controller.js');
require('./js/subscription.experience.list.controller.js');
require('./js/user.info.controller.js');
require('./js/user.info.detail.controller.js');
require('./js/user.settings.controller.js');
require('./js/camera.controller.js');
require('./js/upload.controller.js');
require('./js/class.pack.controller.js');
require('./js/directives.js');
require('./js/filters.js');

require('./js/app.js');
require('./js/config/init.env.js');
require('./js/config/logprovider.config.js');
require('./js/config/segment.config.js');
require('./js/configuration.js');
require('./js/cookies_config.js');
require('./js/tao_config.js');
require('./js/document.js');
requireAll(require.context('../elements/', true, /\.js$/));
require('../components/login/login.js');
require('../components/application/application.js');
require('../components/application/themeService.js');
require('../components/login/googleSigninDirective.js');
require('../components/login/gg4lSigninDirective.js');
require('../components/login/cleverSigninDirective.js');
require('../components/login/classlinkSigninDirective.js');
require('../components/classroomAssignment/classroomAssignment.js');
require('../components/resetPassword/resetPassword.js');
require('../components/accountSettings/accountSettings.js');
require('../components/userIcon/userIcon.js');
require('../components/elements/elementStorage.js');
require('../components/elements/directives.js');
require('../components/helpVideos/helpVideos.js');
require('../components/helpVideos/videoLink.js');
require('../components/helpVideos/helpVideoService.js');
require('../components/fullPageAlert/fullPageAlert.js');
require('../components/fullPageModal/fullPageModal.js');
require('../components/navbarPage/navbarPage.js');
require('../components/navbarPage/loginNotificationsService.js');
require('../components/navSidebar/navSidebar.js');
require('../components/fixedPageModal/fixedPageModal.js');
require('../components/subscriptionLicense/subscriptionLicense.js');
require('../components/subscriptionLicenseSchools/subscriptionLicenseSchools.js');
require('../components/subscriptionImport/subscriptionImport.js');
require('../components/classImport/import.js');
require('../components/classRoster/roster.js');
require('../components/classRoster/campus.js');
require('../components/classRoster/rosterNavigation.js');
require('../components/classRoster/rosterServices.js');
require('../components/classRoster/studentList.js');
require('../components/classAllStudents/students.js');
require('../components/districtLicense/districtLicense.js');
require('../components/school/school.js');
require('../components/school/noSchool.js');
require('../components/report/report.js');
require('../components/report/reportHelperService.js');
require('../components/fullPageReport/fullPageReport.js');
require('../components/fullPageReport/fullPageReportDirectives.js');
require('../components/reportFilter/reportFilter.js');
require('../components/reportFilter/reportFilterDropdown.js');
require('../components/reportFilter/reportExportDropdown.js');
require('../components/reportPicker/reportPicker.js');
require('../components/reportSummary/reportSummary.js');
require('../components/studentProgressReport/studentProgressReport.js');
require('../components/experienceSummaryReport/experienceSummaryReport.js');
require('../components/studentSummaryReport/studentSummaryReport.js');
require('../components/curriculumProgressReport/curriculumProgressReport.js');
require('../components/curriculumTEKSSummary/curriculumTEKSSummary.js');
require('../components/curriculumTEKSDetails/curriculumTEKSDetails.js');
require('../components/schoolDistrictTEKSSummary/schoolDistrictTEKSSummary.js');
require('../components/standardsStudentLearning/standardsCluster.js');
require('../components/standardsStudentLearning/standardsHeader.js');
require('../components/standardsStudentLearning/standardsExperiences.js');
require('../components/standardsStudentLearning/studentLearningService.js');
require('../components/standardsStudentLearning/studentLearningReport.js');
require('../components/standardsStudentLearning/standardsLegend.js');
require('../components/analyzerReport/analyzerReport.js');
require('../components/analyzerReport/chartDirective.js');
require('../components/analyzerReport/lineChart.js');
require('../components/analyzerReport/pieChart.js');
require('../components/analyzerReport/dataTable.js');
require('../components/analyzerReport/elementResponses.js');
require('../components/iSpireClassReport/spireReportingServices.js');
require('../components/iSpireClassReport/spireReportingDirectives.js');
require('../components/iSpireClassReport/spireClassLevelProgress.js');
require('../components/iSpireClassReport/spireStudentSummary.js');
require('../components/iSpireClassReport/spireReportingModels.js');
require('../components/iSpireAdminReport/spireAdminLevelProgress.js');
require('../components/iSpireClassReport/spireExportServices.js');
require('../components/passageDifferentiator/passageDifferentiator.js');
require('../components/assignExperience/assignExperience.js');
require('../components/experienceTemplates/templateStandards.js');
require('../components/experienceTemplates/standardServices.js');
require('../components/quiz/quiz.js');
require('../components/quiz/quizService.js');
require('../components/quiz/quizTimer.js');
require('../components/quiz/tiaStudents.js');
require('../components/connectToClass/connectToClass.js');
require('../components/experienceActivity/experienceNotificationDirective.js');
require('../components/experienceActivity/googleDocDirective.js');
require('../components/experienceDashboard/elementScorePopup.js');
require('../components/experienceHeader/dates.js');
require('../components/experienceList/experienceListFilters.js');
require('../components/experienceList/notificationService.js');
require('../components/adminConsole/adminConsoleUsers.js');
require('../components/adminConsole/adminConsoleUsersAndSeats.js');
require('../components/adminConsole/adminConsoleImportRosters.js');
require('../components/adminConsole/adminConsoleInviteTeachers.js');
require('../components/adminConsole/adminConsoleUsersService.js');
require('../components/adminConsole/adminConsoleService.js');
require('../components/adminConsole/adminConsoleReportPicker.js');
require('../components/adminConsole/adminConsoleUsersModal.js');
require('../components/adminConsole/adminConsoleUsersNav.js');
require('../components/videoStreaming/videoStreaming.js');
require('../components/videoStreaming/videoStreamingService.js');
require('../components/videoStreaming/teacherStreamingService.js');
require('../components/videoStreaming/studentStreamingService.js');
require('../components/videoStreaming/participatingStudents.js');
require('../components/videoStreaming/studentControls.js');
require('../components/videoStreaming/draggableVideo.js');
require('../components/videoStreaming/videoControls.js');
require('../components/videoStreaming/session.js');
require('../components/videoStreaming/publisher.js');
require('../components/videoStreaming/subscriber.js');
require('../components/videoStreaming/subscriberStream.js');
require('../components/google/googleDrive.js');
require('../components/google/googleServices.js');
require('../components/canvas/canvasServices.js');
require('../components/schoology/schoologyServices.js');
require('../components/classCreate/newClass.js');
require('../components/classCreate/courseModals.js');
require('../components/onBoarding/onBoardingDirective.js');
require('../components/onBoarding/taskList.js');
require('../components/onBoarding/onBoarding.js');
require('../components/onBoarding/tourService.js');
require('../components/onBoarding/tourIcon.js');
require('../components/licensePack/licensePack.js');
require('../components/licensePack/licensePackService.js');
require('../components/epsHelp/epsHelp.js');
require('../components/epsHelp/epsHelpService.js');
require('./js/templates/client-templates.js');
require('./js/templates/element-templates.js');
require('./js/templates/component-templates.js');
require('./js/utils/analytics.js');

window.EXPLOROS_FRONT_END_VERSION = '3.0.10';
