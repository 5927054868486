module.exports = {
  port : 7777,
  newrelic: true,
  realtime: {
    url: 'wss://realtime.exploros.com/v1'
  },
  cookies : {
    token_cookie_name : 'prod_exploros_token',
    session_details_cookie_name : 'prod_session_details',
    domain : '.exploros.com',
    old_cookie_name : 'exploros_token'
  },
};

